// Import lib
import { Container } from "react-bootstrap";

// Import SCSS
import "./hero.scss";

// Import image
import heroCover from "../../Assets/image/heroCam.svg";
function Hero() {
  return (
    <>
      <div className="Hero-Background" id="trangchu">
        <Container className="Hero-Container">
          <div className="Hero-Textbox">
            <div className="Hero-Textbox-Heading">
              Hàng rào an ninh ảo <br />
              Cảnh báo tức thì
            </div>
            <p style={{ fontWeight: 400, fontSize: `3rem`, color: `#fff` }}>
              Camera Lupa với khả năng xem hình ảnh có màu vào ban đêm cùng còi
              đèn cảnh báo sẽ bảo vệ ngôi nhà bạn mọi lúc.
            </p>
            <button
              style={{
                border: `none`,
                paddingTop: `2.4rem`,
                paddingBottom: `2.4rem`,
                paddingRight: `4.8rem`,
                paddingLeft: `4.8rem`,
                fontSize: `3rem`,
                fontWeight: `600`,
                color: `#23A490`,
                backgroundColor: `#fff`,
                borderRadius: `1.2rem`,
              }}
            >
              Tìm hiểu thêm
            </button>
          </div>
          <img src={heroCover} alt="Hero-cover" className="Hero-Cover" />
        </Container>
      </div>
    </>
  );
}
export default Hero;
