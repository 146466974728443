import Container from "react-bootstrap/Container";
// import scss
import "./FeatureCard.scss";
function FeatureCard({ image, cam, warn, type }) {
  return (
    <Container>
      <div className="featurecard-container">
        <div>
          <h1>
            Camera <span>{cam}</span>
            <br />
            {warn ? <span>Cảnh báo</span> : <span>Thường</span>}
          </h1>
          <img src={image} alt="cam1" />
        </div>
        <div>
          {type === "bulletNormal" && (
            <>
              <div>
                <p>Model</p>
                <p>LP-CI22EB-I/M2</p>
              </div>
            </>
          )}
          {type === "turretNormal" && (
            <>
              <div>
                <p>Model</p>
                <p>LP-CI23EB-I/M2</p>
              </div>
            </>
          )}
          {type === "turretWarn" && (
            <>
              <div>
                <p>Model</p>
                <p>LP-CI42EB-IP/SM2</p>
              </div>
            </>
          )}
          {type === "bulletWarn" && (
            <>
              <div>
                <p>Model</p>
                <p>LP-CI42EB-IP/SM2</p>
              </div>
            </>
          )}
          {type === "bulletWarn" && (
            <>
              <div>
                <p>Độ phân giải</p>
                <p>4MP, 2K</p>
              </div>
            </>
          )}
          {type === "turretWarn" && (
            <>
              <div>
                <p>Độ phân giải</p>
                <p>4MP, 2K</p>
              </div>
            </>
          )}
          {type === "bulletNormal" && (
            <>
              <div>
                <p>Độ phân giải</p>
                <p>2MP, FHD</p>
              </div>
            </>
          )}
          {type === "turretNormal" && (
            <>
              <div>
                <p>Độ phân giải</p>
                <p>2MP, FHD</p>
              </div>
            </>
          )}
          <div>
            <p>Ống kính</p>
            {type === "turretNormal" ? (
              <p>105.59&#176; @3.6mm</p>
            ) : (
              <p>90.4&#176; @3.6mm</p>
            )}
          </div>
          <div>
            <p>Khoảng cách nhìn đêm</p>
            <p>Đen trắng (IR): 30m</p>
            <p>Có màu: 25m</p>
          </div>
          <div>
            <p>Nguồn</p>
            <p>12VDC - 1A / Active PoE</p>
          </div>
          <div>
            <p>Chống nước</p>
            <p>IP66</p>
          </div>
        </div>
        <div>
          <div className="d-flex" style={{ gap: `20rem` }}>
            <div>
              <p>Loa</p>
              {type === "bulletWarn" && (
                <>
                  <p>Có</p>
                </>
              )}
              {type === "turretWarn" && (
                <>
                  <p>Có</p>
                </>
              )}
              {type === "bulletNormal" && (
                <>
                  <p>không</p>
                </>
              )}
              {type === "turretNormal" && (
                <>
                  <p>không</p>
                </>
              )}
            </div>
            <div>
              <p>Mic</p>
              <p>Có</p>
            </div>
          </div>
          <div>
            <p>Kết nối mạng</p>
            <p>LAN(PoE)</p>
          </div>
          <div>
            <p>Lưu trữ</p>
            <p>Thẻ nhớ Micro SD / Cloud</p>
          </div>
          <div>
            <p>Chuẩn nén</p>
            <p>Video: H264, H265</p>
            <p>Audio: G.711</p>
          </div>
          <div>
            <p>Cảnh báo thông minh</p>
            {type === "bulletWarn" && (
              <>
                <p>Phát hiện người và ô tô sử dụng AI</p>
              </>
            )}
            {type === "turretWarn" && (
              <>
                <p>Phát hiện người và ô tô sử dụng AI</p>
              </>
            )}
            {type === "bulletNormal" && (
              <>
                <p>Phát hiện chuyển động</p>
              </>
            )}
            {type === "turretNormal" && (
              <>
                <p>Phát hiện chuyển động</p>
              </>
            )}
          </div>
          <div>
            <p>Kích thước hộp</p>
            {type === "turretNormal" ? (
              <>
                <p>110x110x105mm</p>
              </>
            ) : (
              <>
                <p>160x86x85mm</p>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
export default FeatureCard;
