// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* *********************************************** */
/* Main-section */
.main-text {
  margin-top: 8rem;
}

.text-heading {
  font-size: 3rem;
  color: #008e4f;
  padding-top: 3rem;
}

.text-heading.heading {
  padding-bottom: 9rem;
}

.text-inner {
  font-size: 1.8rem;
  font-weight: 450;
  line-height: 2.2;
}

.contact-list:last-child {
  color: #008e4f;
}

.chinhsachbaomat {
  text-decoration: none;
  color: #008e4f;
  font-weight: 500;
}

@media (max-width: 120em) {
  html {
    font-size: 63.5%;
  }
  .main-text {
    margin-top: 10rem;
    padding: 2rem 0 5rem;
  }
  .text-heading {
    margin: 3rem 0 2rem 0;
  }
  .text-inner {
    margin-bottom: 1rem;
    text-align: justify;
  }
}
@media (min-width: 120em) {
  .main-text {
    margin: 13rem 0 5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/PrivacyPolicy/PrivacyPolicyDes.scss"],"names":[],"mappings":"AAAA,oDAAA;AACA,iBAAA;AACA;EACE,gBAAA;AACF;;AACA;EACE,eAAA;EACA,cAAA;EACA,iBAAA;AAEF;;AAAA;EACE,oBAAA;AAGF;;AADA;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;AAIF;;AAFA;EACE,cAAA;AAKF;;AAHA;EACE,qBAAA;EACA,cAAA;EACA,gBAAA;AAMF;;AAJA;EACE;IACE,gBAAA;EAOF;EALA;IACE,iBAAA;IACA,oBAAA;EAOF;EALA;IACE,qBAAA;EAOF;EALA;IACE,mBAAA;IACA,mBAAA;EAOF;AACF;AALA;EACE;IACE,oBAAA;EAOF;AACF","sourcesContent":["/* *********************************************** */\r\n/* Main-section */\r\n.main-text {\r\n  margin-top: 8rem;\r\n}\r\n.text-heading {\r\n  font-size: 3rem;\r\n  color: #008e4f;\r\n  padding-top: 3rem;\r\n}\r\n.text-heading.heading {\r\n  padding-bottom: 9rem;\r\n}\r\n.text-inner {\r\n  font-size: 1.8rem;\r\n  font-weight: 450;\r\n  line-height: 2.2;\r\n}\r\n.contact-list:last-child {\r\n  color: #008e4f;\r\n}\r\n.chinhsachbaomat {\r\n  text-decoration: none;\r\n  color: #008e4f;\r\n  font-weight: 500;\r\n}\r\n@media (max-width: 120em) {\r\n  html {\r\n    font-size: 63.5%;\r\n  }\r\n  .main-text {\r\n    margin-top: 10rem;\r\n    padding: 2rem 0 5rem;\r\n  }\r\n  .text-heading {\r\n    margin: 3rem 0 2rem 0;\r\n  }\r\n  .text-inner {\r\n    margin-bottom: 1rem;\r\n    text-align: justify;\r\n  }\r\n}\r\n@media (min-width: 120em) {\r\n  .main-text {\r\n    margin: 13rem 0 5rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
