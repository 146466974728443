// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hero-Background {
  background-color: #23a490;
  position: relative;
}
.Hero-Container {
  padding-top: 8.7rem;
}
.Hero-Cover {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.Hero-Textbox {
  padding: 14rem 0 11rem;
  width: 57rem;
}
.Hero-Textbox p {
  margin-bottom: 8rem;
  text-align: justify;
}
.Hero-Textbox-Heading {
  font-size: 6.3rem;
  font-weight: 600;
  color: #fff;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: left;
  margin-bottom: 3.2rem;
}

.Hero-Icon-Container {
  display: flex;
  justify-content: start;
  align-items: end;
  gap: 5rem;
  padding: 5rem 0;
}
.Hero-Icon-Apple {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Hero-Icon-Apple p {
  font-size: 2rem;
  font-weight: 650;
  color: #333;
  padding-bottom: 1rem;
}
.Hero-Icon-Apple a img {
  width: 20rem;
}
.Hero-Icon-Gofa-Qrcode img {
  width: 12rem;
}`, "",{"version":3,"sources":["webpack://./src/Component/Hero/hero.scss"],"names":[],"mappings":"AACE;EACE,yBAAA;EACA,kBAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,kBAAA;EACA,QAAA;EACA,QAAA;EACA,2BAAA;AAAJ;AAEE;EACE,sBAAA;EACA,YAAA;AAAJ;AACI;EACE,mBAAA;EACA,mBAAA;AACN;AAEE;EACE,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,qBAAA;AAAJ;;AAKE;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,SAAA;EACA,eAAA;AAFJ;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;AADN;AAGI;EACE,YAAA;AADN;AAKI;EACE,YAAA;AAHN","sourcesContent":[".Hero {\r\n  &-Background {\r\n    background-color: #23a490;\r\n    position: relative;\r\n  }\r\n  &-Container {\r\n    padding-top: 8.7rem;\r\n  }\r\n  &-Cover {\r\n    position: absolute;\r\n    right: 0;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n  }\r\n  &-Textbox {\r\n    padding: 14rem 0 11rem;\r\n    width: 57rem;\r\n    p {\r\n      margin-bottom: 8rem;\r\n      text-align: justify;\r\n    }\r\n  }\r\n  &-Textbox-Heading {\r\n    font-size: 6.3rem;\r\n    font-weight: 600;\r\n    color: #fff;\r\n    letter-spacing: -1px;\r\n    line-height: 1.2;\r\n    text-align: left;\r\n    margin-bottom: 3.2rem;\r\n  }\r\n}\r\n\r\n.Hero-Icon {\r\n  &-Container {\r\n    display: flex;\r\n    justify-content: start;\r\n    align-items: end;\r\n    gap: 5rem;\r\n    padding: 5rem 0;\r\n  }\r\n  &-Apple {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    & p {\r\n      font-size: 2rem;\r\n      font-weight: 650;\r\n      color: #333;\r\n      padding-bottom: 1rem;\r\n    }\r\n    & a img {\r\n      width: 20rem;\r\n    }\r\n  }\r\n  &-Gofa-Qrcode {\r\n    & img {\r\n      width: 12rem;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
