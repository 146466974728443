// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Feature-Section-Heading {
  text-align: center;
  font-size: 5rem;
  font-weight: 500;
  color: #333;
  letter-spacing: -1px;
  line-height: 1.2;
  margin-bottom: 10rem !important;
}

@media (min-width: 1025px) {
  .detail-1 {
    padding-left: 36rem;
  }
  .detail-2 {
    padding-right: 32rem;
  }
  .detail-3 {
    padding-left: 28rem;
  }
  .detail-4 {
    padding-right: 30rem;
  }
  .detail-5 {
    padding-left: 28rem;
  }
}
@media (min-width: 1200px) {
  .detail-4 {
    padding-right: 39rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Component/Feature/Feature.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,oBAAA;EACA,gBAAA;EACA,+BAAA;AAAJ;;AAGA;EACE;IACE,mBAAA;EAAF;EAEA;IACE,oBAAA;EAAF;EAEA;IACE,mBAAA;EAAF;EAEA;IACE,oBAAA;EAAF;EAEA;IACE,mBAAA;EAAF;AACF;AAEA;EACE;IACE,oBAAA;EAAF;AACF","sourcesContent":[".Feature-Section {\r\n  &-Heading {\r\n    text-align: center;\r\n    font-size: 5rem;\r\n    font-weight: 500;\r\n    color: #333;\r\n    letter-spacing: -1px;\r\n    line-height: 1.2;\r\n    margin-bottom: 10rem !important;\r\n  }\r\n}\r\n@media (min-width: 1025px) {\r\n  .detail-1 {\r\n    padding-left: 36rem;\r\n  }\r\n  .detail-2 {\r\n    padding-right: 32rem;\r\n  }\r\n  .detail-3 {\r\n    padding-left: 28rem;\r\n  }\r\n  .detail-4 {\r\n    padding-right: 30rem;\r\n  }\r\n  .detail-5 {\r\n    padding-left: 28rem;\r\n  }\r\n}\r\n@media (min-width: 1200px) {\r\n  .detail-4 {\r\n    padding-right: 39rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
