import { Container } from "react-bootstrap";
import "../PrivacyPolicy/PrivacyPolicyDes.scss";
import { useEffect } from "react";
function TermOfService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section class="main-text">
        <Container>
          <div class="text-box-1">
            <p class="text-heading text-center heading">
              ĐIỀU KHOẢN DỊCH VỤ
              <br />
              QUAN TRỌNG &#8211; CẦN ĐỌC KỸ.
            </p>
            <p class="text-inner">
              CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY (“ĐIỀU KHOẢN DỊCH VỤ”) LÀ THỎA THUẬN
              PHÁP LÝ GIỮA BẠN VÀ CÔNG TY CỔ PHẦN LUPA VIỆT NAM VÀ/HOẶC CÁC CÔNG
              TY LIÊN KẾT LIÊN QUAN CỦA LUPA (GỌI CHUNG LÀ “LUPA”) VỀ LUPA
              (“DỊCH VỤ”) DO LUPA HOẶC NHÀ CUNG CẤP BÊN THỨ BA HAY BÊN CẤP PHÉP
              CỦA LUPA CUNG CẤP. BẰNG CÁCH ĐỒNG Ý VỚI NHỮNG ĐIỀU KHOẢN DỊCH VỤ
              NÀY HOẶC CÀI ĐẶT HAY SỬ DỤNG DỊCH VỤ, BẠN ĐỒNG Ý CHỊU SỰ RÀNG BUỘC
              CỦA NHỮNG ĐIỀU KHOẢN DỊCH VỤ NÀY. NẾU BẠN KHÔNG ĐỒNG Ý VỚI ĐIỀU
              KHOẢN DỊCH VỤ NÀY, VUI LÒNG KHÔNG CÀI ĐẶT HAY SỬ DỤNG DỊCH VỤ.
            </p>
            <p class="text-inner">
              LUPA, là một ứng dụng và dịch vụ liên quan do LUPA cung cấp. LUPA
              cho phép bạn kết nối từ xa với các sản phẩm tiêu dùng tương thích
              và phù hợp với DỊCH VỤ (“Sản phẩm”), do LUPA sản xuất, phân phối
              hoặc bán. Một số sản phẩm được bên thứ ba sản xuất, phân phối hoặc
              bán cũng có thể dùng được với DỊCH VỤ (“Sản phẩm của bên thứ ba”).
              LUPA có thể dùng với các Sản phẩm tương thích, có thể bao gồm công
              tắc, công tắc bình nóng lạnh, rèm cửa, âm thanh đa vùng, bộ IR,
              điện thoại thông minh, đồng hồ thông minh, máy tính bảng, với mục
              đích (i) điều khiển Sản phẩm, (ii) cung cấp chế độ quan sát nhà
              của bạn hoặc các địa điểm xung quanh Sản phẩm trong thời gian thực
              thông qua camera tích hợp của Sản phẩm và/hoặc Sản phẩm của bên
              thứ ba, và/hoặc gửi tin nhắn đẩy tới điện thoại thông minh khi
              phát hiện thấy bất kỳ chuyển động nào tại địa điểm của bạn, hoặc
              (iii) cung cấp bất kỳ dịch vụ liên quan nào khác tương thích với
              LUPA.
            </p>
            <p class="text-inner">
              Bạn nên xem lại Hướng dẫn sử dụng Sản phẩm của LUPA có sẵn tại
              <a href="http://lupa.vn"> https://lupa.vn </a>https://LUPA.vn để
              biết thông tin về tính tương thích trước khi kết nối với Sản phẩm
              của bên thứ ba. Bạn có thể tự kết nối Sản phẩm hoặc Sản phẩm của
              bên thứ ba của bản thân, được mời để có quyền điều khiển Sản phẩm
              hoặc Sản phẩm của bên thứ ba của người dùng khác thông qua thiết
              bị của bạn, hoặc mời người khác điều khiển Sản phẩm hoặc Sản phẩm
              của bên thứ ba của bạn. Xin lưu ý rằng nếu bạn chọn mời một người
              dùng khác, người dùng được mời đó sẽ có toàn quyền điều khiển
              thiết bị kết nối.
            </p>
            <p class="text-inner">
              DỊCH VỤ HOẶC MỘT SỐ TÍNH NĂNG CỦA DỊCH VỤ CÓ THỂ BỊ TẮT HOẶC NGỪNG
              LẠI THEO CÁC LUẬT, QUY ĐỊNH HOẶC BẤT CỨ NGUYÊN TẮC NÀO ĐƯỢC ÁP
              DỤNG TẠI KHU VỰC CỦA BẠN.
            </p>
            <p class="text-inner">
              ĐIỀU KHOẢN DỊCH VỤ NÀY BỔ SUNG CHO ĐIỀU KHOẢN VÀ ĐIỀU KIỆN CỦA
              DỊCH VỤ LUPA (“ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ DỊCH VỤ”) CÓ SẴN TẠI
              ĐIỀU KHOẢN DỊCH VỤ LUPA ÁP DỤNG ĐỐI VỚI NHỮNG TÀI KHOẢN CỦA BẠN ĐÃ
              ĐĂNG KÝ VỚI LUPA (“TÀI KHOẢN LUMI”). BẠN PHẢI CÓ MỘT TÀI KHOẢN
              LUPA ĐỂ SỬ DỤNG DỊCH VỤ. NẾU BẠN CHƯA CÓ TÀI KHOẢN LUPA, VUI LÒNG
              ĐỌC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ DỊCH VỤ ĐƯỢC CUNG CẤP QUA LIÊN KẾT
              NÀY ĐIỀU KHOẢN DỊCH VỤ LUPA VÀ TẠO MỘT TÀI KHOẢN LUPA MỚI TRƯỚC
              KHI SỬ DỤNG DỊCH VỤ. ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ DỊCH VỤ LÀ MỘT
              PHẦN KHÔNG THỂ TÁCH RỜI CỦA NHỮNG ĐIỀU KHOẢN DỊCH VỤ NÀY. TRONG
              TRƯỜNG HỢP CÓ BẤT KỲ SỰ KHÔNG THỐNG NHẤT HAY MÂU THUẪN NÀO GIỮA
              ĐIỀU KHOẢN DỊCH VỤ VÀ ĐIỀU KHOẢN VÀ ĐIỀU KIỆN VỀ DỊCH VỤ THÌ QUY
              ĐỊNH CỦA ĐIỀU KHOẢN DỊCH VỤ NÀY SẼ ĐƯỢC ƯU TIÊN ÁP DỤNG. NHỮNG
              ĐIỀU KHOẢN DỊCH VỤ NÀY CÓ THỂ ĐƯỢC CHỈ ĐỊNH CHO BẤT KỲ CÔNG TY
              LIÊN KẾT NÀO CỦA LUPA VÀO BẤT CỨ LÚC NÀO.
            </p>
            <p class="text-inner">
              BẠN XÁC NHẬN VÀ ĐỒNG Ý RẰNG LUPA VÀ/HOẶC CÁC BÊN ĐƯỢC LUPA ỦY THÁC
              CÓ THỂ THU THẬP VÀ SỬ DỤNG NHỮNG THÔNG TIN CẦN THIẾT ĐỂ CUNG CẤP
              DỊCH VỤ VÀ ĐỂ CẬP NHẬT, NÂNG CAO HOẶC CẢI TIẾN DỊCH VỤ. THÔNG TIN
              CỦA BẠN SẼ LUÔN ĐƯỢC XỬ LÝ PHÙ HỢP VỚI LUẬT ÁP DỤNG VÀ CHÍNH SÁCH
              BẢO MẬT CỦA LUPA MÀ BẠN CÓ THỂ XEM TẠI:{" "}
              <a href="https://lupa.vn/policy ">https://lupa.vn/policy</a>
            </p>
          </div>
          <div class="text-box-2">
            <h1 class="text-heading">1. Cấp phép</h1>
            <p class="text-inner">
              Theo các điều khoản của Điều khoản dịch vụ này, LUPA cấp cho bạn
              một giấy phép hạn chế, không độc quyền và có thể hủy ngang để cài
              đặt, truy cập và sử dụng DỊCH VỤ. Các dịch vụ và tính năng do DỊCH
              VỤ cung cấp có thể thay đổi hoặc bị giới hạn tùy thuộc vào một số
              yếu tố nhất định bao gồm nhưng không giới hạn ở quốc gia, thiết
              bị, hệ điều hành hoặc nhà điều hành mạng của bạn. Nếu bạn là trẻ
              vị thành niên tại nước sở tại (“Trẻ vị thành niên”), bạn phải
              tuyên bố rằng bạn đã xem xét Điều khoản dịch vụ với cha mẹ hoặc
              người giám hộ hợp pháp và rằng bạn và cha mẹ hoặc người giám hộ
              hiểu và đồng ý các điều khoản và điều kiện của Điều khoản dịch vụ.
              Nếu bạn là cha mẹ hoặc người giám hộ cho phép Trẻ vị thành niên sử
              dụng DỊCH VỤ thì bạn đồng ý: (i) giám sát việc sử dụng DỊCH VỤ của
              Trẻ vị thành niên; (ii) chịu tất cả các rủi ro liên quan tới việc
              sử dụng DỊCH VỤ của Trẻ vị thành niên, (iii) chịu mọi trách nhiệm
              pháp lý do việc sử dụng DỊCH VỤ của Trẻ vị thành niên gây ra; (iv)
              đảm bảo tính chính xác, trung thực của thông tin do Trẻ vị thành
              niên gửi; và (v) chịu trách nhiệm và chịu sự ràng buộc của Điều
              khoản dịch vụ về việc truy cập và sử dụng DỊCH VỤ của Trẻ vị thành
              niên.
              <br />
              Các thông tin dữ liệu giao thông được LUPA cung cấp mang tính tham
              khảo, bạn hãy luôn giữ sự tập trung quan sát trong quá trình lái
              xe để đảm bảo chấp hành đúng quy tắc giao thông và giữ gìn an toàn
              cho bản thân cũng như những người xung quanh và cả cộng đồng.
            </p>
          </div>
          <div class="text-box-3">
            <h1 class="text-heading">2. Bảo lưu các Quyền và Quyền sở hữu</h1>
            <p class="text-inner">
              DỊCH VỤ này bao gồm phần mềm và dữ liệu được các bên thứ ba cung
              cấp. Bạn xác nhận rằng DỊCH VỤ thuộc quyền sở hữu của LUPA và bên
              cấp phép của LUPA và được bảo vệ theo luật và hiệp định hiện hành
              về bản quyền và quyền sở hữu trí tuệ. Điều khoản dịch vụ và việc
              bạn sử dụng DỊCH VỤ không cấp cho bạn bất kỳ quyền lợi sở hữu nào
              đối với DỊCH VỤ và bạn chỉ được cấp một giấy phép có giới hạn để
              sử dụng mà có thể hủy ngang theo các điều khoản của Điều khoản
              dịch vụ. Bạn không được sử dụng DỊCH VỤ để sao chép hoặc vi phạm
              các tài liệu có bản quyền, hoặc các tài liệu mà bạn không có quyền
              hoặc được chấp thuận hợp pháp để sao chép. Đối với những hành vi
              vi phạm quyền sở hữu trí tuệ của bên thứ ba một cách nghiêm trọng
              hoặc lặp đi lặp lại, LUPA có toàn quyền chấm dứt quyền sử dụng
              DỊCH VỤ của bạn.
            </p>
          </div>
          <div class="text-box-4">
            <h1 class="text-heading">3. Hạn chế</h1>
            <p class="text-inner">
              Bạn không được phép (i) đảo ngược kỹ thuật, dịch ngược, tháo rời
              hoặc tìm cách phát hiện mã nguồn hoặc các thuật toán của DỊCH VỤ;
              (ii) sửa đổi hoặc vô hiệu hóa bất kỳ tính năng nào của DỊCH VỤ;
              (iii) tạo ra những tác phẩm phái sinh dựa trên DỊCH VỤ, ngoại trừ
              khi pháp luật địa phương quy định rằng bạn có thể làm như vậy;
              (iv) thuê, cho thuê, cho mượn, cấp phép phụ hoặc cung cấp dịch vụ
              máy chủ thương mại với DỊCH VỤ; (v) vi phạm quyền sở hữu trí tuệ
              của chúng tôi hoặc của bất kỳ bên thứ ba nào liên quan đến việc
              bạn sử dụng DỊCH VỤ (trong phạm vi việc sử dụng đó không được Điều
              khoản dịch vụ này cấp phép); (vi) sử dụng DỊCH VỤ dưới bất kỳ hình
              thức bất hợp pháp nào, cho những mục đích bất hợp pháp hoặc dưới
              bất kỳ hình thức nào không phù hợp với Điều khoản dịch vụ hoặc
              hành động gian lận hay hiểm độc, ví dụ như, bằng cách vi phạm bất
              kỳ quyền sở hữu trí tuệ hoặc bản quyền của người khác hoặc xâm
              nhập hoặc chèn mã độc hại hoặc dữ liệu có hại, bao gồm vi-rút, vào
              DỊCH VỤ hoặc bất kỳ hệ điều hành nào; (vii) sử dụng DỊCH VỤ theo
              cách có thể gây tổn hại, vô hiệu hóa, làm quá tải, làm suy giảm
              hoặc làm hại hệ thống hoặc an ninh của chúng tôi hoặc gây trở ngại
              cho người dùng khác; hoặc (viii) thu thập hoặc khai thác bất kỳ
              thông tin hoặc dữ liệu nào từ DỊCH VỤ hay các hệ thống của chúng
              tôi hoặc cố gắng giải mã bất kỳ sự truyền dẫn nào đến hoặc từ các
              máy chủ chạy DỊCH VỤ.
            </p>
          </div>
          <div class="text-box-5">
            <h1 class="text-heading">4. Chính sách tranh chấp bản quyền</h1>
            <p class="text-inner">
              LUPA có quyền (i) chặn truy cập hoặc xóa tài liệu mà chúng tôi tin
              là tài liệu có bản quyền đã được bạn sao chép và phân phối trái
              phép và (ii) xóa và ngừng DỊCH VỤ đối với những người tái phạm.
              Hãy nhớ rằng việc bạn sử dụng DỊCH VỤ luôn tuân theo Điều khoản
              dịch vụ, bao gồm Chính sách tranh chấp bản quyền này. Bất kỳ thuật
              ngữ nào LUPA sử dụng trong Chính sách này mà không định nghĩa đều
              đã được định nghĩa trong Điều khoản dịch vụ.
            </p>
            <p class="text-inner">
              4.1 Quy trình thông báo vi phạm bản quyền. Nếu bạn tin rằng tài
              liệu hoặc nội dung hiện có hoặc có thể truy cập qua DỊCH VỤ vi
              phạm bản quyền của bạn (hoặc bản quyền của người mà bạn được ủy
              quyền quyết định thay), vui lòng gửi thông báo về vi phạm bản
              quyền có chứa thông tin sau tại LUPA Support.
            </p>
            <p class="text-inner">
              a. Chữ ký thực hoặc chữ ký điện tử của người được ủy quyền quyết
              định thay cho chủ sở hữu bản quyền được cho là bị vi phạm;
            </p>
            <p class="text-inner">
              b. Thông tin nhận diện tác phẩm hoặc tài liệu bị vi phạm;
            </p>
            <p class="text-inner">
              c. Thông tin nhận diện tài liệu được cho là vi phạm, bao gồm thông
              tin về vị trí của các tài liệu vi phạm mà chủ sở hữu bản quyền
              đang tìm cách xoá bỏ, với đầy đủ chi tiết để LUPA có khả năng tìm
              kiếm và xác minh sự tồn tại của nó;
            </p>
            <p class="text-inner">
              d. Thông tin liên hệ của người thông báo bao gồm địa chỉ, số điện
              thoại và địa chỉ email (nếu có);
            </p>
            <p class="text-inner">
              e. Một tuyên bố rằng người thông báo tin là tài liệu được xác định
              trong 4.1 (c) là không được chủ sở hữu, đại lý sở hữu bản quyền
              hoặc pháp luật cho phép; và
            </p>
            <p class="text-inner">
              f. Một tuyên bố rằng bạn sẽ chịu tội khai man nếu các thông tin
              được cung cấp là không chính xác và bên thông báo được ủy quyền
              làm đơn khiếu nại thay mặt chủ sở hữu bản quyền.
            </p>
            <p class="text-inner">
              4.2 Khi Đại lý được chỉ định nhận được Thông báo vi phạm chính
              đáng. Khi nhận được thông báo chính đáng về vi phạm bản quyền,
              chúng tôi có quyền: (i) xóa hoặc vô hiệu hóa quyền truy cập tài
              liệu vi phạm; (ii) thông báo cho nhà cung cấp nội dung bị cáo buộc
              là vi phạm rằng chúng tôi đã xóa hoặc vô hiệu hóa quyền truy cập
              tài liệu áp dụng; và (iii) chấm dứt quyền truy cập DỊCH VỤ của nhà
              cung cấp nội dung đó nếu họ tái phạm.
            </p>
            <p class="text-inner">
              4.3 Quy trình cung cấp thông báo phản đối cho Đại lý được chỉ
              định. Nếu nhà cung cấp nội dung tin rằng tài liệu đã bị xóa (hoặc
              tài liệu mà quyền truy cập bị vô hiệu hóa) không vi phạm hoặc nhà
              cung cấp nội dung tin rằng họ có quyền đăng và sử dụng các tài
              liệu đó theo sự cho phép của chủ sở hữu bản quyền hoặc đại lý của
              chủ sở hữu bản quyền theo luật pháp, nhà cung cấp nội dung có
              quyền gửi cho chúng tôi thông báo phản đối có chứa thông tin sau
              cho Đại lý được chỉ định: (i) chữ ký thực hoặc chữ ký điện tử của
              nhà cung cấp nội dung; (ii) thông tin nhận diện tài liệu đã bị xoá
              hoặc tài liệu mà quyền truy cập đã bị vô hiệu hóa và vị trí mà tài
              liệu đã xuất hiện trước khi nó bị xóa hoặc bị vô hiệu hóa; (iii)
              một tuyên bố rằng nhà cung cấp nội dung tin là tài liệu đã bị xoá
              hoặc vô hiệu hóa là do nhầm lẫn hoặc nhận diện nhầm tài liệu; và
              (iv) tên, địa chỉ, số điện thoại và địa chỉ email (nếu có) của nhà
              cung cấp nội dung; và một tuyên bố rằng cá nhân hoặc tổ chức đó
              đồng ý với thẩm quyền của Tòa Án đối với khu vực tư pháp tại địa
              chỉ của nhà cung cấp nội dung.
            </p>
            <p class="text-inner">
              Nếu Đại lý được chỉ định nhận được thông báo phản đối, LUPA có thể
              gửi một bản sao thông báo phản đối cho bên khiếu nại ban đầu thông
              báo cho cá nhân đó biết rằng LUPA có thể thay thế tài liệu bị xóa
              hoặc dừng việc vô hiệu hóa tài liệu đó trong vòng 10 ngày làm
              việc. Trừ khi chủ sở hữu bản quyền nộp đơn xin lệnh của tòa án
              chống lại nhà cung cấp nội dung bị cáo buộc vi phạm, tài liệu bị
              xóa có thể được thay thế hoặc quyền truy cập tài liệu được khôi
              phục trong vòng từ 10 đến 14 ngày làm việc trở lên sau khi Đại lý
              được chỉ định nhận được thông báo phản đối.
            </p>
          </div>
          <div class="text-box-6">
            <h1 class="text-heading">
              5. Bản cập nhật, Thay đổi hoặc Tạm dừng DỊCH VỤ
            </h1>
            <p class="text-inner">
              Tại bất kỳ thời điểm nào, LUPA có thể cung cấp hoặc tạo bản cập
              nhật hoặc nâng cấp cho DỊCH VỤ (“Bản cập nhật”), bao gồm nhưng
              không chỉ giới hạn ở các bản vá lỗi, cải tiến dịch vụ, tính năng
              mới, xóa các chức năng hiện có hoặc sửa đổi DỊCH VỤ. Một số Bản
              cập nhật có thể khiến các Sản phẩm, phần mềm hoặc thiết lập cũ
              không còn hoạt động được với DỊCH VỤ nữa và bạn có thể được yêu
              cầu cập nhật hoặc nâng cấp Sản phẩm để tiếp tục sử dụng DỊCH VỤ.
              Bản cập nhật sẽ chịu sự chi phối của Điều khoản dịch vụ trừ khi
              Điều khoản và Điều kiện riêng được cung cấp kèm theo Bản cập nhật
              đó, trong trường hợp này, Điều khoản và Điều kiện riêng sẽ chi
              phối Bản cập nhật. Bản cập nhật cho phần mềm bảo mật, các bản vá
              lỗi quan trọng hoặc Bản cập nhật quan trọng khác có thể tự động
              được tải xuống và cài đặt một cách kịp thời do tầm quan trọng của
              việc nhận Bản cập nhật này.
            </p>
            <p class="text-inner">
              Bằng cách cài đặt DỊCH VỤ, bạn đồng ý việc LUPA kích hoạt DỊCH VỤ
              và đồng ý với tất cả các bản cập nhật và nâng cấp trong tương lai.
              Bạn có thể rút lại sự đồng ý của mình bất kỳ lúc nào bằng việc
              đóng TÀI KHOẢN LUPA của bạn. Để yêu cầu trợ giúp, vui lòng liên hệ
              LUPA để được hỗ trợ tại LUPA Support.
            </p>
            <p class="text-inner">
              Bạn xác nhận và hiểu và đồng ý rằng DỊCH VỤ (bao gồm bất kỳ bản
              cập nhật hoặc nâng cấp nào) sẽ (i) khiến cho thiết bị của bạn tự
              động kết nối với máy chủ của chúng tôi để cung cấp chức năng của
              DỊCH VỤ (như được mô tả khi bạn tải về DỊCH VỤ hiện hành), (ii)
              ảnh hưởng đến các tùy chọn liên quan đến ứng dụng hoặc dữ liệu
              được lưu trữ trong thiết bị của bạn và (iii) thu thập thông tin cá
              nhân như được quy định trong Chính sách bảo mật của LUPA có sẵn
              tại{" "}
              <a href="./chinhsachbaomat.html" class="chinhsachbaomat">
                ĐÂY
              </a>
            </p>
          </div>
          <div class="text-box-7">
            <h1 class="text-heading">6. Dịch vụ và Nội dung của bên thứ ba</h1>
            <p class="text-inner">
              Bạn cũng có thể được cung cấp dịch vụ và/hoặc nội dung của bên thứ
              ba thông qua DỊCH VỤ. Trong phạm vi được pháp luật cho phép, LUPA
              khước từ mọi bảo đảm, điều kiện hoặc cam đoan về các khía cạnh
              liên quan đến nội dung và dịch vụ của bên thứ ba, bao gồm nhưng
              không giới hạn ở chất lượng, độ chính xác, tính hiệu quả, việc
              không có vi-rút, việc không vi phạm các quyền của bên thứ ba và
              việc tuân thủ mọi luật và quy định áp dụng. Việc sử dụng các dịch
              vụ và nội dung của bên thứ ba có thể chịu sự chi phối của điều
              khoản sử dụng của nhà cung cấp bên thứ ba hay thỏa thuận cấp phép,
              chính sách bảo mật hoặc thỏa thuận khác tương tự. Một số phần nhất
              định của DỊCH VỤ có thể chịu sự chi phối của giấy phép mã nguồn
              mở, trong đó các điều khoản của giấy phép mã nguồn mở đó có thể
              được ưu tiên hơn những điều khoản của Điều khoản dịch vụ này liên
              quan đến phần đó của DỊCH VỤ.
            </p>
          </div>
          <div class="text-box-8">
            <h1 class="text-heading">7. Đồng ý thu thập và Sử dụng dữ liệu</h1>
            <p class="text-inner">
              Bạn xác nhận và đồng ý rằng LUPA và/hoặc các bên được LUPA ủy thác
              có thể thu thập và sử dụng những thông tin cần thiết để cung cấp
              DỊCH VỤ và Bản cập nhật, nâng cao hoặc cải thiện DỊCH VỤ, và bảo
              trì Sản phẩm và/hoặc Sản phẩm của bên thứ ba mà bạn kết nối. Ví
              dụ: Sản phẩm video hoặc ghi âm hoặc dữ liệu GPS mà bạn liên kết
              đến tài khoản LUPA của bạn có thể thu thập thông tin cá nhân.
              Thông tin của bạn sẽ luôn được xử lý phù hợp với Thông báo về
              quyền riêng tư riêng biệt mà bạn đồng ý trước khi sử dụng DỊCH VỤ
              và Chính sách bảo mật của LUPA mà bạn có thể xem tại: LUPA. Bạn có
              trách nhiệm nhận được (và bạn tuyên bố và đảm bảo rằng bạn đã nhận
              được) mọi sự đồng ý có thể phải có theo yêu cầu của luật địa
              phương hiện hành từ tất cả các cá nhân sử dụng DỊCH VỤ thông qua
              bạn hoặc lời mời của bạn, những người mà LUPA có thể thu thập
              thông tin.
            </p>
          </div>
          <div class="text-box-9">
            <h1 class="text-heading">8. Truy cập dữ liệu</h1>
            <p class="text-inner">
              Một số tính năng của DỊCH VỤ và việc cập nhật hoặc nâng cấp DỊCH
              VỤ có thể yêu cầu thiết bị di động của bạn có truy cập internet và
              có thể chịu các hạn chế do nhà cung cấp mạng hoặc internet của bạn
              đặt ra. DỊCH VỤ có thể yêu cầu truy cập Internet bằng cách sử dụng
              mạng di động, điều này có thể làm phát sinh thêm chi phí tùy thuộc
              vào gói thanh toán của bạn. Ngoài ra, việc bạn tận hưởng một số
              tính năng của DỊCH VỤ có thể chịu sự ảnh hưởng của tính tương
              thích và hiệu năng phần cứng của thiết bị hay khả năng truy cập dữ
              liệu của bạn.
            </p>
          </div>
          <div class="text-box-10">
            <h1 class="text-heading">9. Chi phí và Quảng cáo</h1>
            <p class="text-inner">
              Mặc dù LUPA ban đầu chỉ được cung cấp cho bạn với các tính năng
              miễn phí nhưng LUPA bảo lưu quyền thêm tính năng và chức năng có
              thu phí trong tương lai. LUPA cũng bảo lưu quyền hiển thị quảng
              cáo liên quan đến LUPA.
            </p>
          </div>
          <div class="text-box-11">
            <h1 class="text-heading">10. Tuân thủ pháp luật</h1>
            <p class="text-inner">
              Bạn xác nhận và đồng ý tuân thủ tất cả luật và quy định áp dụng
              liên quan đến việc sử dụng DỊCH VỤ, bao gồm nhưng không giới hạn ở
              tất cả luật và quy định áp dụng về lái xe.
            </p>
          </div>
          <div class="text-box-12">
            <h1 class="text-heading">11. Chấm dứt</h1>
            <p class="text-inner">
              Điều khoản dịch vụ này có hiệu lực cho tới khi được chấm dứt. Bạn
              có thể chấm dứt Điều khoản dịch vụ bằng việc đóng TÀI KHOẢN LUPA
              của bạn. Bạn xác nhận rằng nếu bạn đóng TÀI KHOẢN LUPA, bạn sẽ
              không được truy cập vào tất cả các dịch vụ có sẵn thông qua TÀI
              KHOẢN LUPA, thông tin TÀI KHOẢN LUPA của bạn hoặc mọi tập tin, nội
              dung người dùng hoặc các sản phẩm của bên thứ ba được lưu trữ bằng
              TÀI KHOẢN LUPA của bạn. Nếu bạn không tuân thủ Điều khoản dịch vụ
              thì các quyền dựa trên Điều khoản dịch vụ của bạn sẽ tự động chấm
              dứt mà không có thông báo từ LUPA. Khi Điều khoản dịch vụ chấm
              dứt, bạn phải dừng ngay toàn bộ việc sử dụng DỊCH VỤ. Để cho rõ
              ràng, LUPA có thể chấm dứt Điều khoản dịch vụ và DỊCH VỤ vì bất cứ
              lý do nào. Để yêu cầu trợ giúp, vui lòng liên hệ LUPA để được hỗ
              trợ tại LUPA Support.
            </p>
          </div>
          <div class="text-box-13">
            <h1 class="text-heading">
              12. Tuyên bố miễn trừ trách nhiệm bảo đảm
            </h1>
            <p class="text-inner">
              MỘT SỐ SẢN PHẨM VÀ/HOẶC SẢN PHẨM CỦA BÊN THỨ BA CÓ THỂ KHÔNG HOẠT
              ĐỘNG HOẶC CÓ THỂ DỪNG HOẠT ĐỘNG VỚI LUPA MẶC DÙ CHÚNG HỖ TRỢ CÁC
              TIÊU CHUẨN NHƯ NHAU. LUPA KHÔNG CAM ĐOAN HOẶC ĐẢM BẢO TÍNH TƯƠNG
              THÍCH CỦA CÁC SẢN PHẨM CỦA BÊN THỨ BA, NGAY CẢ KHI CÁC SẢN PHẨM
              CỦA BÊN THỨ BA CÓ THỂ TRUY CẬP ĐƯỢC THÔNG QUA DỊCH VỤ. TRONG PHẠM
              VI TỐI ĐA ĐƯỢC LUẬT ÁP DỤNG CHO PHÉP, DỊCH VỤ ĐƯỢC LUPA CUNG CẤP
              TRÊN CƠ SỞ “NGUYÊN TRẠNG” VÀ “SẴN CÓ” MÀ KHÔNG CÓ BẢO ĐẢM HOẶC
              ĐIỀU KIỆN HỢP ĐỒNG DƯỚI BẤT KỲ HÌNH THỨC NÀO, DÙ LÀ RÕ RÀNG HAY
              NGỤ Ý. TRONG PHẠM VI TỐI ĐA ĐƯỢC LUẬT ÁP DỤNG CHO PHÉP, LUPA TUYÊN
              BỐ MIỄN TRỪ MỌI TRÁCH NHIỆM BẢO ĐẢM, DÙ ĐƯỢC NÊU RÕ RÀNG, NGỤ Ý
              HAY THEO LUẬT ĐỊNH, BAO GỒM NHƯNG KHÔNG GIỚI HẠN Ở CÁC BẢO ĐẢM NGỤ
              Ý VỀ KHẢ NĂNG TIÊU THỤ, CHẤT LƯỢNG ĐÁP ỨNG YÊU CẦU, TÍNH PHÙ HỢP
              CHO MỤC ĐÍCH CỤ THỂ, ĐỘ TIN CẬY HAY SẴN CÓ, KHÔNG CÓ LỖI, ĐỘ CHÍNH
              XÁC, KHÔNG CÓ VI-RÚT, SỰ TẬN HƯỞNG TRONG YÊN TĨNH, KHÔNG VI PHẠM
              CÁC QUYỀN CỦA BÊN THỨ BA HOẶC CÁC VI PHẠM VỀ QUYỀN KHÁC. MỘT SỐ
              KHU VỰC TÀI PHÁN KHÔNG CHO PHÉP VIỆC LOẠI TRỪ HOẶC GIỚI HẠN CÁC
              BẢO ĐẢM NGỤ Ý NÊN CÁC LOẠI TRỪ HOẶC GIỚI HẠN Ở TRÊN CÓ THỂ KHÔNG
              ÁP DỤNG ĐỐI VỚI BẠN. MỌI LỜI KHUYÊN HAY THÔNG TIN, DÙ Ở DẠNG NÓI
              HAY DẠNG VĂN BẢN MÀ BẠN NHẬN ĐƯỢC TỪ LUPA HOẶC CÔNG TY LIÊN KẾT
              CỦA LUPA ĐỀU KHÔNG ĐƯỢC XEM LÀ BẰNG CHỨNG ĐỂ THAY ĐỔI TUYÊN BỐ
              MIỄN TRỪ TRÁCH NHIỆM BẢO ĐẢM NÀY CỦA LUPA LIÊN QUAN ĐẾN DỊCH VỤ,
              HOẶC TẠO RA BẤT KỲ NGHĨA VỤ BẢO ĐẢM NÀO CỦA LUPA.
            </p>
          </div>
          <div class="text-box-14">
            <h1 class="text-heading">13. Giới hạn trách nhiệm pháp lý</h1>
            <p class="text-inner">
              BẠN XÁC NHẬN RẰNG CÁC QUY ĐỊNH TRÊN Ở KHOẢN 12 VÀ 13 LÀ HỢP LÝ KHI
              CÂN NHẮC ĐẾN LỢI ÍCH CỦA DỊCH VỤ VÀ BẠN SẼ CHẤP NHẬN RỦI RO ĐÓ
              VÀ/HOẶC BẢO HÀNH MỘT CÁCH TƯƠNG XỨNG.
            </p>
            <p class="text-inner">
              ĐIỀU QUAN TRỌNG LÀ BẠN PHẢI SAO LƯU DỮ LIỆU TRÊN THIẾT BỊ CỦA BẠN
              TRƯỚC KHI SỬ DỤNG DỊCH VỤ ĐỂ TRÁNH MẤT DỮ LIỆU. LUPA SẼ KHÔNG CHỊU
              TRÁCH NHIỆM CHO BẤT KỲ DỮ LIỆU NÀO BẠN LÀM MẤT DO VIỆC BẠN SỬ DỤNG
              DỊCH VỤ.
            </p>
          </div>
          <div class="text-box-16">
            <h1 class="text-heading">
              14. Luật áp dụng và giải quyết tranh chấp
            </h1>
            <p class="text-inner">
              (a) Luật áp dụng. Điều khoản dịch vụ chịu sự chi phối của pháp
              luật của nước sở tại nơi bạn đang cư trú, không kể đến các điều
              khoản về mâu thuẫn luật. Điều khoản dịch vụ sẽ không chịu sự chi
              phối của Công ước Liên Hiệp Quốc về Hợp đồng Mua bán Hàng hóa Quốc
              tế, việc áp dụng công ước này được loại trừ một cách rõ ràng.
            </p>
            <p class="text-inner">
              (b) Giải quyết tranh chấp. Nếu bạn là khách hàng (và trong trường
              hợp thuật ngữ này không được định nghĩa khác đi trong luật pháp
              địa phương của bạn, tức là&#34; không sử dụng DỊCH VỤ như là một
              phần trong hoạt động kinh doanh, buôn bán hoặc chuyên môn &#34;)
              thì Tòa án tại lãnh thổ nơi bạn đang cư trú có thẩm quyền không
              độc quyền để tổ chức phiên điều trần và đưa ra phán quyết trong
              các vụ tranh chấp, tranh cãi hoặc mâu thuẫn phát sinh từ Điều
              khoản dịch vụ này hoặc DỊCH VỤ. Trong trường hợp pháp luật địa
              phương cho phép việc lựa chọn tòa án thì tòa án phải là tòa án tại
              thủ đô của đất nước bạn. Nếu bạn không phải là khách hàng và nếu
              có tranh chấp, tranh cãi hoặc mâu thuẫn phát sinh theo bất kỳ cách
              nào từ Điều khoản dịch vụ này hay việc bạn sử dụng DỊCH VỤ không
              giải quyết được bằng hòa giải thì vụ việc đó sẽ phải chịu thủ tục
              trọng tài theo luật trọng tài của Phòng Thương mại Quốc tế tại
              nước của bị đơn. Không kể các quy định trên đây, LUPA có thể gửi
              đơn yêu cầu biện pháp khẩn cấp tạm thời (hoặc biện pháp pháp lý
              khẩn cấp tương tự) tại bất kỳ tòa án nào có thẩm quyền. Các phiên
              điều trần trọng tài sẽ được thực hiện bằng tiếng Anh.
            </p>
            <p class="text-inner">
              (c) Tranh chấp giữa người dùng. Nếu có tranh chấp giữa bạn và
              Người dùng khác liên quan đến DỊCH VỤ, hoặc giữa bạn và bất kỳ bên
              thứ ba nào, bạn đồng ý rằng LUPA không có nghĩa vụ phải tham gia.
              Trong trường hợp bạn có tranh chấp với một hoặc nhiều Người dùng,
              bạn sẽ miễn cho LUPA, cán bộ, nhân viên, đại lý và người kế nhiệm
              của LUPA khỏi các yêu cầu bồi thường và thiệt hại dưới mọi loại
              hình hoặc tính chất, đã xác định hay chưa xác định, bị nghi ngờ
              hoặc không bị nghi ngờ, bị tiết lộ hoặc không bị tiết lộ, phát
              sinh từ hoặc dưới bất kỳ hình thức nào liên quan đến các tranh
              chấp và/hoặc DỊCH VỤ.
            </p>
          </div>
          <div class="text-box-17">
            <h1 class="text-heading">15. Toàn bộ thỏa thuận</h1>
            <p class="text-inner">
              Điều khoản dịch vụ là toàn bộ thỏa thuận giữa bạn và LUPA liên
              quan đến DỊCH VỤ và thay thế tất cả các thông tin, đề nghị và
              tuyên bố trước đây hay hiện tại bằng lời nói hoặc bằng văn bản
              liên quan tới DỊCH VỤ hay bất kỳ vấn đề nào khác thuộc phạm vi của
              Điều khoản dịch vụ. Nếu bất kỳ quy định nào của Điều khoản dịch vụ
              bị xem là vô hiệu, không hợp lệ, không thể thực hiện hoặc bất hợp
              pháp thì các quy định khác sẽ tiếp tục có hiệu lực thi hành.
            </p>
          </div>
          <div class="text-box-18">
            <h1 class="text-heading">16. Thay đổi Điều khoản dịch vụ</h1>
            <p class="text-inner">
              Vui lòng kiểm tra lại các sửa đổi đối với Điều khoản dịch vụ này.
              Nếu LUPA thực hiện sửa đổi quan trọng, LUPA sẽ thông báo cho bạn
              về những thay đổi đó và bạn có thể xem xét các sửa đổi trước khi
              tiếp tục sử dụng DỊCH VỤ.
            </p>
            <p class="text-inner">[Cập nhật ngày 15 tháng 08 năm 2022]</p>
          </div>
        </Container>
      </section>
    </>
  );
}
export default TermOfService;
