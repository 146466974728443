// Import boostrap css
import "bootstrap/dist/css/bootstrap.min.css";

// Import Page
import Home from "./Page/home";
//Import Lib
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Import Component
import PrivacyPolicyDes from "./Component/PrivacyPolicy";
import TermOfService from "./Component/TermOfService";
import Navigation from "./Component/Navbar";

import { useState } from "react";
// Import GlobalStyle
import "./GlobalStyle.scss";
import Footer from "./Component/Footer";
function App() {
  const bgcolor = {
    transperant: {
      backgroundColor: "transparent",
    },
    color: {
      backgroundColor: "rgba(255, 255, 255, 0.97)",
      boxShadow: "0 1.2rem 3.2rem rgb(0 0 0 / 3%)",
      paddingTop: `0rem`,
    },
  };
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 100) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Router>
      {colorChange ? (
        <Navigation style={bgcolor.color} logoWhite={true} />
      ) : (
        <Navigation style={bgcolor.transperant} />
      )}
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/apps" element={<Home />} />
        </Route>
        <Route path="/policy" element={<TermOfService />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
