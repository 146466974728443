import Container from "react-bootstrap/Container";
import "./Footer.scss";

import fb from "../../Assets/image/facebook.svg";
import insta from "../../Assets/image/insta.svg";
import twitter from "../../Assets/image/twitter.svg";
import logo from "../../Assets/image/LogoLupa2.svg";
function Footer() {
  return (
    <>
      <section className="footer-section" id="lienhevoichungtoi">
        <Container>
          <div className="contact">
            <ul className="contact-list">
              <li className="list-item">
                <img
                  src={logo}
                  alt="Logo LUPA"
                  style={{ width: `10rem`, paddingBottom: `3.2rem` }}
                />
              </li>
              <li
                className="list-item d-flex"
                style={{ gap: `3rem`, paddingBottom: `3.2rem` }}
              >
                <img src={fb} alt="facebook" />
                <img src={insta} alt="twitter" />
                <img src={twitter} alt="insta" />
              </li>
              <li className="list-item">
                <p>
                  Copyright © 2022 by Lupa.
                  <br /> All rights reserved.
                </p>
              </li>
            </ul>
            <p className="contact-list">
              <p
                style={{
                  color: `#000`,
                  fontWeight: `600`,
                  paddingBottom: `3.2rem`,
                }}
              >
                Liên hệ
              </p>
              <p>
                Khu công nghiệp Thăng Long Vĩnh Phúc, Xã Thiện Kế, Huyện Bình
                Xuyên, Tỉnh Vĩnh Phúc
              </p>
            </p>
            <p className="contact-list">
              <p
                style={{
                  color: `#000`,
                  fontWeight: `600`,
                  paddingBottom: `3.2rem`,
                }}
              >
                Resources
              </p>
              <p>Trung tâm hỗ trợ</p>
              <p>
                <a href="https://lupa.vn/policy">Điều khoản dịch vụ</a>
              </p>
            </p>
          </div>
        </Container>
      </section>
    </>
  );
}
export default Footer;
