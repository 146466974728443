// Import Lib
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import $ from "jquery";
// Import Image
import logo1 from "../../Assets/image/LogoLupa.svg";
import logo2 from "../../Assets/image/LogoLupa2.svg";
// Import SCSS
import "./Navbar.scss";
function Navigation({ style, logoWhite }) {
  $(document).ready(function () {
    // Add smooth scrolling to all links
    $("a").on("click", function (event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top,
          },
          850,
          function () {
            // Add hash (#) to URL when done scrolling (default click behavior)
            window.location.hash = hash;
          }
        );
      } // End if
    });
  });
  return (
    <>
      <Navbar expand="lg" collapseOnSelect fixed="top" style={style}>
        <Container className="Navbar-Containter" fluid="lg">
          <Navbar.Brand className="Navbar-Logo">
            <Link to="/trangchu">
              {logoWhite ? (
                <img src={logo2} alt="gofa-logo" />
              ) : (
                <img src={logo1} alt="gofa-logo" />
              )}
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton />
            <Offcanvas.Body className="Navbar-Offcanvas-Body">
              {/* <Nav>
                <a href="#trangchu">Giới thiệu</a>
                <a href="#tinhnang">Sản phẩm</a>
                <a href="#lienhevoichungtoi">Liên hệ</a>
              </Nav> */}
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigation;
