import Hero from "../Component/Hero";
import Feature from "../Component/Feature";
function Home() {
  return (
    <>
      <Hero />
      <Feature />
    </>
  );
}
export default Home;
