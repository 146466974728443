// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featurecard-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  justify-items: center;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.featurecard-container div h1 {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 56px;
  color: #23a490;
  margin-bottom: 8rem;
}
.featurecard-container div img {
  width: 30rem;
}
.featurecard-container div div {
  margin-bottom: 2.4rem;
}
.featurecard-container div div p {
  margin-bottom: 0;
}
.featurecard-container div div p:first-child {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 28px;
  color: rgba(31, 31, 31, 0.4);
}
.featurecard-container div div p:nth-child(2) {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 32px;
}
.featurecard-container div div p:last-child {
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 32px;
}`, "",{"version":3,"sources":["webpack://./src/Component/Card/FeatureCard/FeatureCard.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sCAAA;EACA,qBAAA;EACA,iBAAA;EACA,oBAAA;AAAJ;AAEM;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;AAAR;AAEM;EACE,YAAA;AAAR;AAEM;EACE,qBAAA;AAAR;AACQ;EACE,gBAAA;AACV;AACQ;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,4BAAA;AACV;AACQ;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;AACV;AACQ;EACE,gBAAA;EACA,iBAAA;EACA,iBAAA;AACV","sourcesContent":[".featurecard {\r\n  &-container {\r\n    display: grid;\r\n    grid-template-columns: 1.5fr 1fr 1.5fr;\r\n    justify-items: center;\r\n    padding-top: 8rem;\r\n    padding-bottom: 8rem;\r\n    div {\r\n      h1 {\r\n        font-size: 4.8rem;\r\n        font-weight: 600;\r\n        line-height: 56px;\r\n        color: #23a490;\r\n        margin-bottom: 8rem;\r\n      }\r\n      img {\r\n        width: 30rem;\r\n      }\r\n      div {\r\n        margin-bottom: 2.4rem;\r\n        p {\r\n          margin-bottom: 0;\r\n        }\r\n        p:first-child {\r\n          font-size: 1.8rem;\r\n          font-weight: 600;\r\n          line-height: 28px;\r\n          color: rgba(31, 31, 31, 0.4);\r\n        }\r\n        p:nth-child(2) {\r\n          font-weight: 400;\r\n          font-size: 2.4rem;\r\n          line-height: 32px;\r\n        }\r\n        p:last-child {\r\n          font-weight: 400;\r\n          font-size: 2.4rem;\r\n          line-height: 32px;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
