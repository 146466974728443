import Container from "react-bootstrap/Container";
import { useEffect } from "react";
import "./PrivacyPolicyDes.scss";
function PrivacyPolicyDes() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section class="main">
        <section class="main-text">
          <Container>
            <div class="text-box-1">
              <h1 class="text-heading">Chính sách bảo mật</h1>
              <p class="text-inner">
                Lumi life hiểu rằng việc giữ bảo mật thông tin của khách hàng
                đóng vai trò vô cùng quan trọng, đặc biệt trong bối cảnh vấn đề
                thu thập và bảo mật dữ liệu đang trở thành mối quan tâm hàng
                đầu.
              </p>
              <p class="text-inner">
                Chúng tôi tin rằng bạn sẽ trải nghiệm sự riêng tư đúng nghĩa khi
                thông tin cá nhân được bảo vệ tốt. Vì sự riêng tư của bạn, Lumi
                life cam kết không bán hay cho thuê thông tin cá nhân liên quan
                đến khách hàng cho bất kỳ bên thứ ba nào. Mọi thông tin bạn cung
                cấp cho chúng tôi đều được bảo mật tối đa.
              </p>
              <p class="text-inner">
                Chính sách bảo mật này quy định cách thức Lumi life thu thập và
                sử dụng thông tin cá nhân bạn cung cấp cho chúng tôi
              </p>
              <p class="text-inner">
                &#8211;&#8211;&#8211;&#8211;&#8211;&#8211;&#8211;
              </p>
            </div>
            <div class="text-box-2">
              <h1 class="text-heading">Sử dụng và chia sẻ thông tin</h1>
              <p class="text-inner">
                Chúng tôi sử dụng thông tin thu thập được về bạn cho các mục
                đích sau:
              </p>
              <p class="text-inner">
                &#8211; Trao đổi với bạn về sản phẩm và dịch vụ của chúng tôi
              </p>
              <p class="text-inner">
                &#8211; Tùy chỉnh và cải thiện dịch vụ dành cho bạn
              </p>
              <p class="text-inner">
                &#8211; Phục vụ các chiến dịch khuyến mại và thúc đẩy tương tác
              </p>
              <p class="text-inner">&#8211; Cho mục đích quảng cáo</p>
              <p class="text-inner">
                &#8211; Điều chỉnh phạm vi và mục đích sử dụng thông tin cá nhân
                theo yêu cầu của bạn
              </p>
              <p class="text-inner">
                &#8211; Cuối cùng, chúng tôi cũng có thể chia sẻ thông tin cá
                nhân của bạn để tuân thủ luật và quy định hiện hành, các yêu cầu
                hợp pháp khác hoặc để bảo vệ quyền lợi của chúng tôi.
              </p>
              <p class="text-inner">
                &#8211;&#8211;&#8211;&#8211;&#8211;&#8211;&#8211;
              </p>
            </div>
            <div class="text-box-3">
              <h1 class="text-heading">Liên hệ</h1>
              <p class="text-inner">
                Để biết thêm thông tin về chính sách bảo mật, hoặc nếu bạn muốn
                khiếu nại, vui lòng liên hệ với chúng tôi qua email{" "}
                <a href="mailto:info@gofa.vn">info@gofa.vn</a> hoặc Hotline:{" "}
                <a href="tel:0904 665 965">0904 665 965</a>.
              </p>
              <p class="text-inner">
                Địa chỉ liên hệ: Tầng 6, tòa nhà Skyline, Văn Quán, Hà Đông, Hà
                Nội.
              </p>
              <p class="text-inner">Chỉnh sửa lần cuối 15/08/2022.</p>
              <p class="text-inner">
                &#8211;&#8211;&#8211;&#8211;&#8211;&#8211;&#8211;
              </p>
            </div>
          </Container>
        </section>
      </section>
    </>
  );
}
export default PrivacyPolicyDes;
