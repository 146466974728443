import Container from "react-bootstrap/Container";

import FeatureCard from "../Card/FeatureCard";

import cam1 from "../../Assets/image/cam3.png";
import cam2 from "../../Assets/image/cam2.png";
import cam3 from "../../Assets/image/cam4.png";
import cam4 from "../../Assets/image/cam1.png";

// import scss
import "./Feature.scss";
function Feature() {
  return (
    <>
      <Container style={{ paddingRight: `0rem`, paddingLeft: `0rem` }}>
        <div>
          <FeatureCard
            image={cam1}
            cam="Bullet"
            warn={true}
            type="bulletWarn"
          />
        </div>
      </Container>
      <Container fluid style={{ paddingRight: `0rem`, paddingLeft: `0rem` }}>
        <div style={{ backgroundColor: `#f2f2f2` }}>
          <FeatureCard
            image={cam2}
            cam="Turret"
            warn={true}
            type="turretWarn"
          />
        </div>
      </Container>
      <Container style={{ paddingRight: `0rem`, paddingLeft: `0rem` }}>
        <div>
          <FeatureCard
            image={cam3}
            cam="Bullet"
            warn={false}
            type="bulletNormal"
          />
        </div>
      </Container>
      <Container fluid style={{ paddingRight: `0rem`, paddingLeft: `0rem` }}>
        <div style={{ backgroundColor: `#f2f2f2` }}>
          <FeatureCard
            image={cam4}
            cam="Turret"
            warn={false}
            type="turretNormal"
          />
        </div>
      </Container>
    </>
  );
}
export default Feature;
